<template>
    <baseContainer>
        <template v-slot:heads>
            <HeadNav/>
        </template>
        <van-pull-refresh class="specialColumn" v-model="refreshing" @refresh="onRefresh">
            <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad">
                <SpecialColumn :list="list"
                               :total="total"
                               @callMore="(item)=>{callMore(item, '/columnDetails')}"/>
            </van-list>
        </van-pull-refresh>
    </baseContainer>
</template>

<script>
import HeadNav from './../../components/headNav';
import SpecialColumn from './components/SpecialColumn';
import DropDownRefresh from './../../mixin/DropDownRefresh';
import api from './../../api/home';

export default {
    name: "specialColumn",
    components: {
        HeadNav,
        SpecialColumn
    },
    mixins: [DropDownRefresh],
    data () {
        return {
            listQuery: {
                current: 1,
                size: 10
            }
        }
    },
    methods: {
        callMore (item, url) {
            this.$utils.callMores(item, url);
        },
        // 查询首页专题列表
        getList () {
            api.getQueryHomeSubjectList(this.listQuery).then(res => {
                if (res) {
                    this.onEnd(res.total, res.records);
                }
            });
        }
    }
}
</script>

<style scoped lang="less">
    .specialColumn {
        min-height: 80vh;
        padding: 20px 40px 40px;
    }
</style>