import HttpClient from '@/utils/http'

export default {
    // 查询首页banner列表
    getHomeBannerList(data = {}) {
        return HttpClient.get(`/cloud-livestream/cms/queryHomeBannerList`, data)
    },
    // 查询门户近期直播列表
    getQueryRecentLiveList(data = {}) {
        return HttpClient.get('/cloud-livestream/live/queryRecentLiveList', data)
    },
    // 查询门户精彩回放
    getHomeLiveMap(data = {}) {
        return HttpClient.get('/cloud-livestream/live/getHomeLiveMap', data)
    },
    // 查询首页课程列表
    getHomeCourseList(data = {}) {
        return HttpClient.get(`/cloud-livestream/course/queryHomeCourseList`, data)
    },
    // 查询首页专题列表
    getQueryHomeSubjectList(data = {}) {
        return HttpClient.get('/cloud-livestream/subject/queryHomeSubjectList', data)
    },
    // 查询首页直播列表
    getQueryHomeList(data = {}) {
        return HttpClient.get(`/cloud-livestream/live/queryHomeList`, data)
    },
    // 查询课程列表（首页展示，无需登录）
    getCourseLists(data) {
        return HttpClient.get(`/cloud-livestream/course/queryList`, data)
    }
}
