<template>
    <div class="SpecialColumn">
        <div class="SpecialColumn_item"
             @click="callMore(item)"
             v-for="(item) in list" :key="item.id">
            <div class="SpecialColumn_titles">
                <h6>{{item[propType.name]}}</h6>
                <img src="../../../assets/images/logo@2x.png" height="14" width="66"/>
            </div>
            <p class="of_eno2">
                {{item[propType.remark]}}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SpecialColumn',
    components: {},
    props: {
        list: {
            type: Array,
            default () {
                return []
            }
        },
        propType: {
            type: Object,
            default () {
                return {
                    name: 'name',
                    remark: 'remark'
                }
            }
        }
    },
    data () {
        return {};
    },
    methods: {
        callMore (item) {
            this.$emit('callMore', item);
        }
    },
    created () {
    },
    mounted () {
    },
    watch: {}
}
</script>

<style scoped lang="less">
    .SpecialColumn {
        .SpecialColumn_item {
            margin-top: 16px;
            padding: 24px;
            background: #FFFFFF;
            border-radius: 6px;
        }
        .SpecialColumn_titles {
            display: flex;
            justify-content: space-between;
            h6 {
                font-size: 28px;
                font-weight: 600;
                color: #0077FF;
                line-height: 28px;
            }
        }
        p {
            margin-top: 20px;
            font-size: 20px;
            font-weight: 400;
            color: #333333;
            line-height: 30px;
        }
    }
</style>
